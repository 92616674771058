* {
	background-attachment: scroll!important;
	-webkit-appearance: none;
}

// Removes Phone Number Styling
a[href^="tel"]{
    color:inherit;
    text-decoration:none;
}

// iOS issue where native components in forms scale view port fix
input[type="text"], input[type="text"]:focus, select:focus, textarea:focus, input:focus {
    font-size: 16px !important;
}

#uplink {
	display: none;
}

.icon{
  opacity: 1!important;
}
